





























































import { Component, Vue } from 'vue-property-decorator';
// import { Store } from 'vuex';
// import { readUserProfile } from '@/store/main/getters';
import { IMeeting, IZoomMeeting } from '@/interfaces';
import { api } from '@/api';
import Meeting from '@/components/Meeting.vue'
import { zoomApiKey, apiUrl } from '@/env';

@Component({
  components: {
    Meeting,
  },
})

export default class MeetingRequest extends Vue {
  meeting_config: IZoomMeeting | null = null;
  scheduled_meeting: IMeeting | null=null;
  show_meeting: boolean = false;
  is_scheduled: boolean = false;

  client: string = '';
  note: string = '';

  processing_id: string = '';

  public async create_meeting(){
    this.scheduled_meeting = (await api.createMeeting(this.client, this.note)).data;
    this.processing_id = this.scheduled_meeting.processing_id;
    this.is_scheduled = true;
  }

  public async start_meeting(){
    const role: number = 0;   // Client
    const sig = (await api.genSignature(this.processing_id, role)).data;
    console.log('Signature', sig);
    console.log('APIKey', zoomApiKey);
    this.meeting_config = {};
    this.meeting_config.apiKey = zoomApiKey;
    // this.meeting_config.leaveUrl = "http://localhost:8080/request";
    // this.meeting_config.leaveUrl = `${apiUrl}/request`;
    this.meeting_config.leaveUrl = "http://rbb.co.jp";
    this.meeting_config.meetingNumber = this.scheduled_meeting!.meeting_id;
    this.meeting_config.passWord = this.scheduled_meeting!.meeting_password;
    this.meeting_config.role = role;
    this.meeting_config.userEmail = '';
    this.meeting_config.userName = this.scheduled_meeting!.client_name;
    this.meeting_config.signature = sig;

    this.show_meeting = true;
  }

}
